.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: auto;
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
}

@media (max-width: 768px) {
  .App-header {
      padding: 10px;
      font-size: 16px;
  }
}
