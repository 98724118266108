/* Main Footer styling */
.footer {
    display: flex;
    justify-content: center; /* Center the icons */
    align-items: center;
    width: 100%;
    background-color: #ffe5ec;
    border-top: 3px solid #ffd0dcb9;
    padding: 20px 0; /* Adjust padding for better responsiveness */
    margin-top: auto; /* Ensure footer stays at the bottom */
}

/* Footer links styling */
.footer-links {
    display: flex;
    gap: 40px; /* Add gap between icons */
    justify-content: center;
}

.footer-links a {
    font-size: 24px;
    color: #6E7477;
    text-decoration: none;
    transition: color 0.3s ease;
}

/* Hover effect for icons */
.footer-links a:hover {
    color: #00B3A6; /* Change color on hover */
}

/* Responsive styling */
@media (max-width: 1024px) {
    .footer-links {
        gap: 30px; /* Reduce gap between icons */
    }

    .footer-links a {
        font-size: 22px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 15px 0;
    }

    .footer-links {
        gap: 20px;
    }

    .footer-links a {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .footer-links {
        gap: 15px; /* Further reduce gap on smaller screens */
    }

    .footer-links a {
        font-size: 18px; /* Reduce icon size for mobile */
    }
}
