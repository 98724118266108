html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    background-color: #ffe5ec;
}

@media (max-width: 768px) {
    .page-container {
        padding: 10px;
    }
}
